/* .footer {
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  text-align: center;
} */
.footer {
  background-color: #1c1c1c;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  bottom: 0;
  color: white;
  text-align: center;
}

.footer-info {
  color: #eef0f2;
}

.footer h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer button {
  width: 50%;
  padding: 0.5rem;
  background-color: #eef0f2;
  color: #1c1c1c;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.footer a {
  color: #1c1c1c;
  text-decoration: none;
}
