.contact-form form {
  display: grid;
  grid-template-columns: [col] 1fr [col] 1fr;
  grid-gap: 1rem 1rem;
  margin-bottom: 8rem;
  overflow: hidden;
}
.contact-info p {
  width: 70%;
  margin: auto;
}
.contact-info h2 {
  margin-bottom: 1rem;
}

.contact-bold {
  font-weight: bold;
}

.contact-background {
  background-color: #ecebe4;
  height: 100vh;
}

.contact-img {
  width: 100%;
  display: none;
}

.contact-info h2 {
  margin-top: 2rem;
}

.test {
  grid-column: col / span 2;
  grid-row: 4;
}

.test2 {
  background-color: #1c1c1c;
  color: #daddd8;
  padding: 0.5rem 0.5rem;
}

.contact {
  background-color: #ecebe4;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

.contact-form {
  background-color: #daddd8;
  padding: 1rem 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin: auto;
  width: 90%;
}

@media (min-width: 1000px) {
  .contact {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 90%;
    margin: auto;
  }
  .contact p {
    width: 60%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .contact-form {
    margin-top: 17rem;
    margin-right: 1rem;
    width: 65%;
  }

  .contact-img {
    display: flex;
    height: 25rem;
    border-radius: 10px;
  }
}
