.main {
  background-color: #ecebe4;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 2rem;
}

.quote {
  margin-bottom: 1.5rem;
  color: white;
  line-height: 2.5rem;
  width: 90%;
  margin: auto;
  padding: 1rem;
}

.quote-background {
  background-image: url(/public/images/background1.jpg);
  background-position: center;
  /* opacity: 0.9; */
  margin: 0 auto;
  height: 24rem;
}

.main-img {
  max-width: 100%;
  /* height: 30%; */
  height: auto;
  justify-content: center;
  border-radius: 5px;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.oMeni h2 {
  color: #1c1c1c;
  margin-bottom: 1rem;
}

.oMeni p {
  color: #0d1b2a;
  margin-bottom: 2rem;
}

.oMeni {
  background-color: #daddd8;
  padding: 2rem 2rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 1rem 2rem;
  margin: 1rem 1rem;
}

.main-btn {
  background-color: #1c1c1c;
  color: #ecebe4;
  padding: 0.5rem 2rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  border: none;
}

@media (max-width: 400px) {
  .quote-background {
    height: 30rem;
  }
  .quote {
    padding-top: 2rem;
  }
}

@media (min-width: 400px) {
  .quote {
    margin-top: 2rem;
  }
  .quote-background {
    height: 27rem;
  }
}

@media (min-width: 600px) {
  .quote {
    margin-top: 3.5rem;
    line-height: 2.8rem;
  }
  .oMeni {
    width: 80%;
  }

  .quote-background {
    height: 22rem;
  }
}

@media (min-width: 1000px) {
  .oMeni {
    display: flex;
    /* align-items: center; */
    gap: 2rem;
    margin-top: 1.5rem;
    width: 80%;
  }

  .opis {
    width: 50%;
  }

  .oMeni p {
    line-height: 1.8rem;
    width: 70%;
    margin: auto;
    margin-bottom: 1rem;
  }

  .main-img {
    /* width: 45%; */
    max-width: 50%;
    height: 32rem;

    /* height: 40rem; */
  }

  .main-btn {
    width: 60%;
  }
  .quote {
    margin-top: 2.5rem;
  }

  .quote-background {
    height: 15rem;
  }
}

@media (min-width: 1500px) {
  .quote {
    margin-top: 3.5rem;
  }
}
