.programi {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgb(28, 28, 28, 0.3);
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.program-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.programi-card {
  width: 85%;
  background-color: #daddd8;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.programi-img {
  max-width: 100%;
  /* margin: auto; */
  /* height: 18rem; */
  height: auto;
  border-radius: 5px;
  margin-top: 0.7rem;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.programi-btn {
  background-color: #1c1c1c;
  color: #ecebe4;
  border: none;
  padding: 0.7rem 5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: bold;
  border-radius: 10px;
}

.programi-card > h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.programi-opis {
  padding: 0.5rem 2rem;
}

@media (min-width: 550px) {
  .programi-img {
    max-width: 70%;
  }
}

@media (min-width: 700px) {
  .programi-img {
    max-width: 70%;
  }

  .program-cards {
    width: 80%;
  }
}

@media (min-width: 1000px) {
  .program-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem 1rem;
    gap: 2rem;
  }

  .programi-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 35rem;
    width: 30%;
  }

  .programi-img {
    max-width: 100%;
    margin: 1rem auto;
  }
}
