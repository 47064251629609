.aboutMassage {
  padding-bottom: 3rem;
}
.massage-background-black {
  background-color: rgb(28, 28, 28, 0.3);
  padding: 1rem 2rem;
}
.massage-background-white {
  background-color: #ecebe4;
  padding: 1rem 2rem;
}

@media (min-width: 600px) {
  .aboutMassage {
    text-align: center;
  }
  .aboutMassage ul {
    list-style: none;
  }
}
