.massage {
  background-color: #ecebe4;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 2rem;
  background-color: #daddd8;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.massage-backgorund {
  background-color: #ecebe4;
  padding-bottom: 10rem;
  padding-top: 2rem;
}

.massage-img {
  max-width: 100%;
  /* height: rem; */
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-top: 1rem;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.massage-btn {
  background-color: #1c1c1c;
  color: #ecebe4;
  border: none;
  padding: 0.5rem 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
  border-radius: 10px;
}

@media (min-width: 550px) {
  .massage-img {
    height: 25rem;
  }
}

@media (min-width: 1000px) {
  .massage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
  }

  .massage-img {
    max-width: 40%;
    /* height: 19rem; */
    height: auto;
  }

  .massage-card {
    width: 60%;
  }

  .massage-card h2 {
    margin-bottom: 1rem;
  }
  .massage-card p {
    width: 70%;
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
  }
}
