.navbar {
  background-color: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  color: #e0e1dd;
}

.logo-img {
  height: 6em;
  margin: auto;
  margin-top: 12px;
}

.navbar-title {
  display: none;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  text-decoration: none;
  color: #e0e1dd;
  padding: 1rem;
  display: block;
}

.navbar-links li:hover {
  background-color: #383838;
}

.toggle-button {
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 700px) {
  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }

  .navbar-links li {
    text-align: center;
  }

  .navbar-links li a {
    padding: 0.5rem 1rem;
  }

  .navbar-links.active {
    display: flex;
  }
}

@media (min-width: 1000px) {
  .navbar-title {
    display: inline;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
  }
}
