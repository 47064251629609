* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Roboto", sans-serif; */
  /* font-family: "Roboto Condensed", sans-serif; */
  font-family: "Anek Devanagari", sans-serif;
}

p {
  margin-bottom: 1rem;
}
