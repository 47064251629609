.AllPrograms {
  background-color: #ecebe4;
  /* padding: 1rem 2rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.AllPrograms-img {
  max-width: 80%;
  border-radius: 10px;
  display: flex;
  margin: 2rem auto;
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
}

.allPrograms-button {
  background-color: #1c1c1c;
  color: #ecebe4;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.allPrograms-background {
  background-color: rgb(28, 28, 28, 0.3);
  padding: 0.5rem 0.5rem;
}

.AllProgramsNavigation {
  background-color: #1c1c1c;
  width: 100%;
  padding-top: 1rem;
  opacity: 0.7;
  padding-bottom: 1rem;
}

.AllProgramsNavigation a {
  text-decoration: none;
  color: #ecebe4;
}

.AllProgramsNavigation ul {
  list-style-type: none;
}

.AllProgramsNavigation li {
  margin-bottom: 0.5rem;
  margin-top: 0.8rem;
}

.AllPrograms-card span {
  font-weight: bold;
}

.AllPrograms-card {
  margin-top: 1rem;
}

.AllPrograms-card h2,
h3 {
  margin-bottom: 0.5rem;
}

.AllPrograms-card ul {
  list-style-type: none;
}

@media (min-width: 1000px) {
  .AllPrograms-img {
    max-width: 50%;
    height: auto;
  }
}
